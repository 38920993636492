/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			text-align: center;
			color: #fff;
            margin: 0 auto;
            transform: translateY(-50%);
		}
	}
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				width: 15px;
				height: 15px;
				background-color: #efefef;
				border-radius: 100%;
				border: 1px solid #3361eb;
                transition: 300ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #3361eb;
				}
			}
			&.slick-active {
				button {
					background-color: #3361eb;
				}
			}
		}
	}
}
/* main slider */

.grid {
	display: grid; 
	gap: 30px;
}

.to-2 {grid-template-columns: 1fr 1fr;}
.to-3 {grid-template-columns: 1fr 1fr 1fr;}
.to-4 {grid-template-columns: 1fr 1fr 1fr 1fr;}

.pagination {padding-bottom: 70px;}
.pagination ul {
	display: flex;
	flex-direction: row;
	list-style: none; padding-left: 0; margin: 0;
	li {margin: 0 2.5px; width: 30px; height: 30px;}
	a {
			display: flex; position: relative;
			width: 100%; height: 100%; color: #151515;
			border-radius: 50%; transition: 400ms;
			justify-content: center;
			align-items: center;
			font-weight: 700;
			&:hover {background-color: #cfe5df;}
			&.disabled {pointer-events: none;
							&:hover {background: transparent;}
			}
			&.active {
				background-color: #0d6626; color: #fff;
			}
			&.arr:after,
			&.to-end:before,
			&.to-start:before {
				content: ' '; position: absolute;
				width: 5px; height: 5px;
				left: 50%; top: 50%;
				border-bottom: 2px solid #151515;
				border-right: 2px solid #151515;
			}
			&.prev:after {transform: translate(-50%, -50%) rotate(135deg);}
			&.next:after {transform: translate(-65%, -50%) rotate(-45deg);}

			&.to-start:after, &.to-start:before{transform: translate(-50%, -50%) rotate(135deg);}
			&.to-end:after, &.to-end:before {transform: translate(-100%, -50%) rotate(-45deg);}

			&.to-start:before {margin-left: 5px;}
			&.to-end:before {margin-left: 5px;}

			&.disabled:after, &.disabled:before {
				border-bottom: 2px solid #ccc;
				border-right: 2px solid #ccc;
				
			}
		}
}

.descr-title {
	color: #151515 !important;
	font-family: "Gilroy - Bold";
	font-weight: 700;
	text-transform: uppercase;
}

.price {
	position: relative;
	width: max-content;
	max-width: 200px;
	line-height: 1;
	color: #151515;
	font-size: 20px;
	font-family: "Gilroy - Bold";
	font-weight: 700;
	letter-spacing: 0.2px;
	&:after {
		content: '\20B8';
		font-size: .92em;
		font-family: inherit;
		position: absolute; right: -1em;
		bottom: 0;
	}
	&.green {
		color: #0d6626;
	}
}

.else {
	p {
		color: #666666; font-family: Gilroy;
		font-size: 16px; font-weight: 400;
		letter-spacing: 0.16px;
		b {
			font-size: 18px; letter-spacing: 0.18px;
			font-weight: 700; color: #151515; 
		}
	}
	.img {
		width: 100%; height: 100%; object-fit: contain;
		object-position: center;
	}
}

.policy {
	color: #666666; font-size: 14px;
	a {
		font-family: "Gilroy - Medium"; font-weight: 500;
		color: #0d6626;text-decoration: underline;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 15px;
		margin-bottom: 15px;
		line-height: 1;
		&:before {
			content: ' ';
			width: 7px; height: 7px;
			border-radius: 50%;
			position: absolute;
			background-color: #0d6626;
			left: 0;
			top: .25em;
		}
	}
}



.col--location,
.col--phone,
.col--email,
.col--time {
	position: relative;
	padding-left: 25px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #0d6626;;
		position: absolute;
		top: 0px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
	top: 2px;
}
.col--time:after {
	content: '\f017';
}

@mixin cut-text {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	margin-bottom: 0;
	overflow: hidden;
}


/* main content */