/* Connection Font Icons */
@font-face {
	font-family: 'FontAwesome';
	src: url('../fonts/fontawesome-webfont.eot');
	src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
	url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
	url('../fonts/fontawesome-webfont.woff') format('woff'), 
	url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
	url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
	font-weight: 400;
	font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
	font-family: 'Gilroy-Bold';
	src: url('../fonts/Gilroy-Bold.eot');
	src: url('../fonts/Gilroy-Bold.woff') format('woff'),
			 url('../fonts/Gilroy-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
font-family: 'Gilroy-Medium';
src: url('../fonts/Gilroy-Medium.eot');
src: url('../fonts/Gilroy-Medium.woff') format('woff'),
		 url('../fonts/Gilroy-Medium.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}
@font-face {
font-family: 'Gilroy-Regular';
src: url('../fonts/Gilroy-Regular.eot');
src: url('../fonts/Gilroy-Regular.woff') format('woff'),
		 url('../fonts/Gilroy-Regular.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Gilroy - Semi Bold';
src: url('../fonts/Gilroy-SemiBold.eot');
src: url('../fonts/Gilroy-SemiBold.woff') format('woff'),
		 url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Gilroy-Black';
src: url('../fonts/Gilroy-Black.eot');
src: url('../fonts/Gilroy-Black.woff') format('woff'),
		 url('../fonts/Gilroy-Black.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}

$medium: 'Gilroy-Medium';
$regular: 'Gilroy-Regular';
$bold: 'Gilroy-Bold';
$black: 'Gilroy-Black';
$semi: 'Gilroy - Semi Bold';
$lgreen: #e0e4dc;
$g6: #666666;
$m-gr: #0d6626;

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

@mixin cut-text {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	margin-bottom: 0;
	overflow: hidden;
}

* {
	box-sizing: border-box;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

input:invalid {box-shadow: none;}
input:moz-submit-invalid {box-shadow: none;}
textarea:invalid {box-shadow: none;} 
textarea:moz-submit-invalid {box-shadow: none;} 

[data-src] {
	opacity: 0;
	&[src] {
		opacity: 1;
		transition-duration: 400ms;
		transition-delay: 0.2s;
	}
}

.preload {
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	pointer-events: none;
	&.load {
		opacity: 0;
		transition: 500ms;
	}
	&.loadR {
		opacity: 1;
		transition: 500ms;
	}
	// .sk-double-bounce {
	// 	width: 80px;
	// 	height: 80px;
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 0;
	// 	right: 0;
	// 	transform: translateY(-50%);
	// 	margin: auto;
	// 	.sk-child {
	// 	    width: 100%;
	// 	    height: 100%;
	// 	    border-radius: 50%;
	// 	    background-color: #099;
	// 	    opacity: 0.6;
	// 	    position: absolute;
	// 	    top: 0;
	// 	    left: 0;
	// 	    animation: sk-double-bounce 2.0s infinite ease-in-out;
	// 	}
	//   	.sk-double-bounce-2 {
	//     	animation-delay: -1.0s;
	//   	}
	// }
}
@keyframes sk-double-bounce {
    0%, 100% {
    	transform: scale(0);
  	}
  	50% {
    	transform: scale(1.0);
  	}
}

body {
	display: flex;
	height: 100vh;
	min-width: 300px;
	padding-top: 132px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	-webkit-text-size-adjust: 100%;
	margin: 0;
    @media screen and (max-width: 768px) {
        display: block;
    }
}

h1 {
	font-family: $medium;
	font-weight: 700;
	font-size: 36px;
	color: #151515;
	line-height: 1;
	margin: 0 0 40px;

	@media screen and (max-width: 1200px) {
		font-size: 32px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 26px;
        margin-bottom: 30px;
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 20px;
    }
}
.index h2 {font-size: 36px;}

h2 {
	font-family: $medium;
	font-size: 30px;
	color: #151515;
	margin: 0 0 40px;
	font-weight: 700;
	@media screen and (max-width: 1200px) {
		font-size: 24px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 22px;
        margin-bottom: 30px;
	}
    @media screen and (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 20px;
    }
}
h3 {
	font-family: $semi;
	font-size: 20px;
	color: #151515;
	@media screen and (max-width: 1024px) {
		font-size: 22px;
	}
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $regular;
	font-size: 22px;
	color: #151515;
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $regular;
	font-size: 18px;
	color: #151515;
}
h6 {
	font-family: $regular;
	font-size: 16px;
	text-transform: uppercase;
	color: #151515;
}
p {
		margin: 0 0 15px;
		font-family: $regular;
		font-size: 1rem;
}
a {
	color: #151515;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #282727;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #282727;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 5px;
	-webkit-appearance: none;
}
.wrapper, .page {
	position: relative;
	flex: 1 0 auto;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
		min-height: 50px;
		width: 100%;
		border-radius: 5px; overflow: hidden;
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		padding: 19px 30px;
		border: 1px solid #dddddd;
		margin-bottom: 20px;
		box-shadow: none;
		outline: 0;
		&:active, &:focus {
			box-shadow: 0 1px 5px rgba(	13, 102, 38, .5);
		}
	}
}

textarea {resize: none;}
.wrapper {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1024px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}

.container {
    width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #0d6626;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: flex; align-items: center; justify-content: center;
	width: auto;
	box-sizing: border-box;
	border-radius: 6px;
	background-color: #0d6626;
	font-family: "Gilroy - Semi Bold";
	font-size: 16px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
	padding: 1.1875em 2.5em;
	line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: transparent!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #fedede ;
		color: #151515;
	}	
}

/* header */
header {

	/* header phones */
	
	/* header phones */

	/* header logo */
	
	/* header logo */

	/* header nav */
	
	/* header nav */

	/* header langs */
	
	/* header langs */
	
	@media screen and (max-width: 1024px) {
		.nav--btn {
			width: 33px;
			height: 30px;
			position: absolute;
			left: 15px;
			top: 11px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #0d6626;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
}



.flex {
	display: flex;
}
.flex.spb {justify-content: space-between;}
.flex.spb.center {align-items: center; justify-content: space-between;}

a:hover {cursor: pointer; color: $g6;}
.fa-chevron-down, .fa-envelope {font-size: 12px!important;}



.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 132px;
	z-index: 88;
	box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
	.upper-navbar {
			background-color: $lgreen;
			width: 100%;
			height: 32px;
			align-items: center;
			&>* {padding-top: 6px;}
			.fa {
					color: $g6;
					font-size: 16px;
					padding-right: 10px;
			}
			.l-select {
					position: absolute;
					display: none;
			}
			.socials {
					width: 140px;
					.fa {padding: 0 15px;}
					a:hover {
							.fa {color: #0d6626;}
					}
			}
	}
	.dw-nv {
			height: 100px;
			align-items: center;
			background-color: #fff;
			.down-nv-list li {
					padding: 0 19px;
					a {
							color: $g6;
							&:hover {
				color: #0d6626;
									cursor: pointer;
			}
			&.active {
				color: #0d6626;
			}
					}
			}
			.drop {
					a {color: #151515; font-weight: 600; font-size: 16px;}
					.drop-phones {display: none;}
					.fa-chevron-down{position: relative; top:-2px}
			}
	}
}

.drop-phones, .l-select {
	background: #fff;
	box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
	min-width: 100%; position: absolute;
	top: 110%; right: 0;
	a {padding: .6em .9em; display: block;}
}

.phone-dd a {
	font-family: "Gilroy - Semi Bold";
	font-weight: 600;
	letter-spacing: 0.48px;
	font-size: 16px;
	color: #151515;
}

.dropdown {position:relative}
.dropdown-menu {position: absolute; top: 100%; right: 0;}

@media(max-width: 1020px) {
	.up-nv, .down-nv-list, .phone-dd a:first-child,
	button.pink {display: none;}
	.header, .header .dw-nv {height: 50px;}
}

.searchbtn {
	cursor: pointer;
	&:hover {color: #0d6626}
}
.searchinput {
	outline: none; font-family: $medium;
	font-size: 16px; 
	max-height: 100%;
	border: none;
	padding-left: .5em;
	width: 0; opacity: 0;
	transition: 800ms;
	margin-right: 10px;
}

.searchinput.shown {
	width: 150px; opacity: 1;
}
/* header */