/* footer */
.footer {
  padding-top: 120px;
  background-color: #f2f2f2;
  .f-upper .container {padding-bottom: 120px;}
  a {
      color: $g6;
      font-size: 16px;
      font-family: $medium;
      &:hover {
          text-decoration: none; color: #0d6626;
      }
      &.active {color: #fdc5c5}
  }
  .f-logo-c {padding-bottom: 30px;}
  .col p {padding-bottom: 60px;}
  .socials {
      width: 120px;
      display: flex;
      justify-content: space-between;
      .fa {font-size: 18px;}
      a:hover {
          color: #0d6626;
      }
  }
  .f-list-heading {
      font-size: 20px;
      color: #151515;
      font-weight: bold;
      padding-bottom: 29px;
  }
  .f-list li {
      padding-bottom: 16px;
      a:hover {
          color: #0d6626
      }
      a:active {color: #fdc5c5;}
  }
  .f-phones {
      a {
          display: block;
          font-size: 20px;
          line-height: 30px;
          color: #151515;
          font-weight: bold;
      }
  }
  
  .fa-phone, .fa-envelope {top: 5px;}
  .f-cts .fa {
      position: absolute;
      color: #0d6626;
      font-size: 18px;
      left: -22px;
  }
  .f-cts li {
      position: relative;
      padding-bottom: 30px;
  }
  .f-mail {text-decoration: underline;}

  .copy {
    background-color: #e0e4dc;
    min-height: 60px;
    .container {height: 60px}
    p, a {
        font-size: 14px;
        margin: 0; padding: 0;
        line-height: 1.2;
        font-weight: 400;
        color: #666666;
    }
    img {vertical-align: middle;}
    a:hover {color: #0d6626}
  }
}


@media(max-width: 930px) {
    .footer .f-upper .container {flex-direction: column;}
    .footer .col {margin-bottom: 25px;}
    .f-cts .f-list {padding-left: 25px;}
    .footer {padding-top: 60px;}
    .footer .col p {padding-bottom: 15px;}
    .footer .socials {padding-bottom: 20px;}
    .footer .f-upper .container {padding-bottom: 15px;}
}
/* footer */