/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
  font-family: 'Gilroy-Bold';
  src: url("../fonts/Gilroy-Bold.eot");
  src: url("../fonts/Gilroy-Bold.woff") format("woff"), url("../fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy-Medium';
  src: url("../fonts/Gilroy-Medium.eot");
  src: url("../fonts/Gilroy-Medium.woff") format("woff"), url("../fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy-Regular';
  src: url("../fonts/Gilroy-Regular.eot");
  src: url("../fonts/Gilroy-Regular.woff") format("woff"), url("../fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy - Semi Bold';
  src: url("../fonts/Gilroy-SemiBold.eot");
  src: url("../fonts/Gilroy-SemiBold.woff") format("woff"), url("../fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy-Black';
  src: url("../fonts/Gilroy-Black.eot");
  src: url("../fonts/Gilroy-Black.woff") format("woff"), url("../fonts/Gilroy-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

* {
  box-sizing: border-box; }

*:after, *:before {
  display: block;
  line-height: 1; }

input:invalid {
  box-shadow: none; }

input:moz-submit-invalid {
  box-shadow: none; }

textarea:invalid {
  box-shadow: none; }

textarea:moz-submit-invalid {
  box-shadow: none; }

[data-src] {
  opacity: 0; }
  [data-src][src] {
    opacity: 1;
    transition-duration: 400ms;
    transition-delay: 0.2s; }

.preload {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none; }
  .preload.load {
    opacity: 0;
    transition: 500ms; }
  .preload.loadR {
    opacity: 1;
    transition: 500ms; }

@keyframes sk-double-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

body {
  display: flex;
  height: 100vh;
  min-width: 300px;
  padding-top: 132px;
  position: relative;
  flex-direction: column;
  font-family: "Gilroy-Regular";
  -webkit-text-size-adjust: 100%;
  margin: 0; }
  @media screen and (max-width: 768px) {
    body {
      display: block; } }

h1 {
  font-family: "Gilroy-Medium";
  font-weight: 700;
  font-size: 36px;
  color: #151515;
  line-height: 1;
  margin: 0 0 40px; }
  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 32px; } }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 26px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 22px;
      margin-bottom: 20px; } }

.index h2 {
  font-size: 36px; }

h2 {
  font-family: "Gilroy-Medium";
  font-size: 30px;
  color: #151515;
  margin: 0 0 40px;
  font-weight: 700; }
  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 24px; } }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 22px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 20px;
      margin-bottom: 20px; } }

h3 {
  font-family: "Gilroy - Semi Bold";
  font-size: 20px;
  color: #151515; }
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 768px) {
    h3 {
      font-size: 20px; } }

h4 {
  font-family: "Gilroy-Regular";
  font-size: 22px;
  color: #151515; }
  @media screen and (max-width: 1024px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 18px; } }

h5 {
  font-family: "Gilroy-Regular";
  font-size: 18px;
  color: #151515; }

h6 {
  font-family: "Gilroy-Regular";
  font-size: 16px;
  text-transform: uppercase;
  color: #151515; }

p {
  margin: 0 0 15px;
  font-family: "Gilroy-Regular";
  font-size: 1rem; }

a {
  color: #151515;
  transition: 300ms;
  text-decoration: none; }
  a:focus {
    color: #282727;
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #282727; }

textarea,
button,
select,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
  border-radius: 5px;
  -webkit-appearance: none; }

.wrapper, .page {
  position: relative;
  flex: 1 0 auto; }
  .wrapper textarea,
  .wrapper input[type="text"],
  .wrapper input[type="email"],
  .wrapper input[type="search"],
  .wrapper input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    min-height: 50px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fff;
    font-family: "Gilroy-Regular";
    font-size: 16px;
    padding: 19px 30px;
    border: 1px solid #dddddd;
    margin-bottom: 20px;
    box-shadow: none;
    outline: 0; }
    .wrapper textarea:active, .wrapper textarea:focus,
    .wrapper input[type="text"]:active,
    .wrapper input[type="text"]:focus,
    .wrapper input[type="email"]:active,
    .wrapper input[type="email"]:focus,
    .wrapper input[type="search"]:active,
    .wrapper input[type="search"]:focus,
    .wrapper input[type="password"]:active,
    .wrapper input[type="password"]:focus, .page textarea:active, .page textarea:focus,
    .page input[type="text"]:active,
    .page input[type="text"]:focus,
    .page input[type="email"]:active,
    .page input[type="email"]:focus,
    .page input[type="search"]:active,
    .page input[type="search"]:focus,
    .page input[type="password"]:active,
    .page input[type="password"]:focus {
      box-shadow: 0 1px 5px rgba(13, 102, 38, 0.5); }

textarea {
  resize: none; }

.wrapper .container {
  padding-top: 50px;
  padding-bottom: 50px; }
  @media screen and (max-width: 1024px) {
    .wrapper .container {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media screen and (max-width: 768px) {
    .wrapper .container {
      padding-top: 15px;
      padding-bottom: 15px; } }

.container {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto; }

.close {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 8px;
  top: 10px;
  opacity: 1;
  outline: none; }
  .close:before, .close:after {
    content: '';
    width: 25px;
    height: 2px;
    background-color: #0d6626;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
    transition: 300ms; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }
  .close:hover:before {
    transform: rotate(135deg); }
  .close:hover:after {
    transform: rotate(45deg); }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #0d6626;
  font-family: "Gilroy - Semi Bold";
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  padding: 1.1875em 2.5em;
  line-height: 1;
  transition: 300ms; }
  .btn:focus, .btn:active {
    background-color: transparent !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #fedede;
    color: #151515; }

/* header */
header {
  /* header phones */
  /* header phones */
  /* header logo */
  /* header logo */
  /* header nav */
  /* header nav */
  /* header langs */
  /* header langs */ }
  @media screen and (max-width: 1024px) {
    header .nav--btn {
      width: 33px;
      height: 30px;
      position: absolute;
      left: 15px;
      top: 11px;
      padding: 0;
      margin: 0;
      outline: none;
      float: none; }
      header .nav--btn span {
        position: absolute;
        left: 0;
        right: 0;
        width: 85%;
        height: 3px;
        margin: 0 auto;
        background-color: #0d6626;
        transition: 350ms; }
        header .nav--btn span:nth-of-type(1) {
          top: 7px; }
        header .nav--btn span:nth-of-type(2) {
          top: 14px; }
        header .nav--btn span:nth-of-type(3) {
          top: 21px; }
      header .nav--btn.open span:nth-of-type(1) {
        top: 14px;
        transform: rotate(45deg); }
      header .nav--btn.open span:nth-of-type(2) {
        opacity: 0; }
      header .nav--btn.open span:nth-of-type(3) {
        top: 14px;
        transform: rotate(-45deg); } }

.flex {
  display: flex; }

.flex.spb {
  justify-content: space-between; }

.flex.spb.center {
  align-items: center;
  justify-content: space-between; }

a:hover {
  cursor: pointer;
  color: #666666; }

.fa-chevron-down, .fa-envelope {
  font-size: 12px !important; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 132px;
  z-index: 88;
  box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1); }
  .header .upper-navbar {
    background-color: #e0e4dc;
    width: 100%;
    height: 32px;
    align-items: center; }
    .header .upper-navbar > * {
      padding-top: 6px; }
    .header .upper-navbar .fa {
      color: #666666;
      font-size: 16px;
      padding-right: 10px; }
    .header .upper-navbar .l-select {
      position: absolute;
      display: none; }
    .header .upper-navbar .socials {
      width: 140px; }
      .header .upper-navbar .socials .fa {
        padding: 0 15px; }
      .header .upper-navbar .socials a:hover .fa {
        color: #0d6626; }
  .header .dw-nv {
    height: 100px;
    align-items: center;
    background-color: #fff; }
    .header .dw-nv .down-nv-list li {
      padding: 0 19px; }
      .header .dw-nv .down-nv-list li a {
        color: #666666; }
        .header .dw-nv .down-nv-list li a:hover {
          color: #0d6626;
          cursor: pointer; }
        .header .dw-nv .down-nv-list li a.active {
          color: #0d6626; }
    .header .dw-nv .drop a {
      color: #151515;
      font-weight: 600;
      font-size: 16px; }
    .header .dw-nv .drop .drop-phones {
      display: none; }
    .header .dw-nv .drop .fa-chevron-down {
      position: relative;
      top: -2px; }

.drop-phones, .l-select {
  background: #fff;
  box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
  min-width: 100%;
  position: absolute;
  top: 110%;
  right: 0; }
  .drop-phones a, .l-select a {
    padding: .6em .9em;
    display: block; }

.phone-dd a {
  font-family: "Gilroy - Semi Bold";
  font-weight: 600;
  letter-spacing: 0.48px;
  font-size: 16px;
  color: #151515; }

.dropdown {
  position: relative; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0; }

@media (max-width: 1020px) {
  .up-nv, .down-nv-list, .phone-dd a:first-child,
  button.pink {
    display: none; }
  .header, .header .dw-nv {
    height: 50px; } }

.searchbtn {
  cursor: pointer; }
  .searchbtn:hover {
    color: #0d6626; }

.searchinput {
  outline: none;
  font-family: "Gilroy-Medium";
  font-size: 16px;
  max-height: 100%;
  border: none;
  padding-left: .5em;
  width: 0;
  opacity: 0;
  transition: 800ms;
  margin-right: 10px; }

.searchinput.shown {
  width: 150px;
  opacity: 1; }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider {
  margin-bottom: 0px !important; }
  .slider .slick-slide {
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide img {
      display: block;
      width: 100%;
      height: auto; }
    .slider .slick-slide .slider__block {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      text-align: center;
      color: #fff;
      margin: 0 auto;
      transform: translateY(-50%); }
  .slider .slick-dots {
    bottom: 30px; }
    .slider .slick-dots li {
      margin: 0px 2px; }
      .slider .slick-dots li button {
        width: 15px;
        height: 15px;
        background-color: #efefef;
        border-radius: 100%;
        border: 1px solid #3361eb;
        transition: 300ms; }
        .slider .slick-dots li button:before {
          display: none; }
        .slider .slick-dots li button:hover {
          background-color: #3361eb; }
      .slider .slick-dots li.slick-active button {
        background-color: #3361eb; }

/* main slider */
.grid {
  display: grid;
  gap: 30px; }

.to-2 {
  grid-template-columns: 1fr 1fr; }

.to-3 {
  grid-template-columns: 1fr 1fr 1fr; }

.to-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr; }

.pagination {
  padding-bottom: 70px; }

.pagination ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
  margin: 0; }
  .pagination ul li {
    margin: 0 2.5px;
    width: 30px;
    height: 30px; }
  .pagination ul a {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    color: #151515;
    border-radius: 50%;
    transition: 400ms;
    justify-content: center;
    align-items: center;
    font-weight: 700; }
    .pagination ul a:hover {
      background-color: #cfe5df; }
    .pagination ul a.disabled {
      pointer-events: none; }
      .pagination ul a.disabled:hover {
        background: transparent; }
    .pagination ul a.active {
      background-color: #0d6626;
      color: #fff; }
    .pagination ul a.arr:after, .pagination ul a.to-end:before, .pagination ul a.to-start:before {
      content: ' ';
      position: absolute;
      width: 5px;
      height: 5px;
      left: 50%;
      top: 50%;
      border-bottom: 2px solid #151515;
      border-right: 2px solid #151515; }
    .pagination ul a.prev:after {
      transform: translate(-50%, -50%) rotate(135deg); }
    .pagination ul a.next:after {
      transform: translate(-65%, -50%) rotate(-45deg); }
    .pagination ul a.to-start:after, .pagination ul a.to-start:before {
      transform: translate(-50%, -50%) rotate(135deg); }
    .pagination ul a.to-end:after, .pagination ul a.to-end:before {
      transform: translate(-100%, -50%) rotate(-45deg); }
    .pagination ul a.to-start:before {
      margin-left: 5px; }
    .pagination ul a.to-end:before {
      margin-left: 5px; }
    .pagination ul a.disabled:after, .pagination ul a.disabled:before {
      border-bottom: 2px solid #ccc;
      border-right: 2px solid #ccc; }

.descr-title {
  color: #151515 !important;
  font-family: "Gilroy - Bold";
  font-weight: 700;
  text-transform: uppercase; }

.price {
  position: relative;
  width: max-content;
  max-width: 200px;
  line-height: 1;
  color: #151515;
  font-size: 20px;
  font-family: "Gilroy - Bold";
  font-weight: 700;
  letter-spacing: 0.2px; }
  .price:after {
    content: '\20B8';
    font-size: .92em;
    font-family: inherit;
    position: absolute;
    right: -1em;
    bottom: 0; }
  .price.green {
    color: #0d6626; }

.else p {
  color: #666666;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px; }
  .else p b {
    font-size: 18px;
    letter-spacing: 0.18px;
    font-weight: 700;
    color: #151515; }

.else .img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; }

.policy {
  color: #666666;
  font-size: 14px; }
  .policy a {
    font-family: "Gilroy - Medium";
    font-weight: 500;
    color: #0d6626;
    text-decoration: underline; }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;
    line-height: 1; }
    .check--list li:before {
      content: ' ';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      position: absolute;
      background-color: #0d6626;
      left: 0;
      top: .25em; }

.col--location,
.col--phone,
.col--email,
.col--time {
  position: relative;
  padding-left: 25px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after,
  .col--time:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 20px;
    color: #0d6626;
    position: absolute;
    top: 0px;
    left: 0; }

.col--location:after {
  content: '\f041'; }

.col--phone:after {
  content: '\f095'; }

.col--email:after {
  content: '\f0e0';
  font-size: 16px;
  top: 2px; }

.col--time:after {
  content: '\f017'; }

/* main content */
/* page content */
.page .news1 .date, .page .news .date {
  color: #0d6626; }

.page .page_nav .active {
  color: #0d6626; }

.page #ask-form {
  max-width: 350px; }

.page .write {
  color: #0d6626;
  text-decoration: none; }
  .page .write:hover {
    text-decoration: underline; }

.page .back_link {
  margin: 25px 0; }

.page p a {
  color: #0d6626;
  text-decoration: underline; }
  .page p a:hover {
    text-decoration: none; }

.page .h2 a {
  font-size: 16px;
  color: #0d6626; }

@media screen and (max-width: 1024px) {
  .page #photos-list td img {
    width: 100%;
    height: auto; } }

@media screen and (max-width: 480px) {
  .page p img, .page p strong img, .page h2 img, .page h3 img, .page h4 img, .page h5 img, .page h6 img, .page p a img, .page strong img {
    width: 100% !important;
    height: auto !important;
    display: block !important;
    float: none !important;
    margin: 0 0 10px 0 !important; }
  .page #page-text p a img {
    width: auto !important;
    max-width: 100% !important; }
  .page #photos-list td {
    width: 50%;
    display: inline-block;
    vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  font-size: 16px; }
  .breadcrumbs .container {
    padding-top: 30px;
    padding-bottom: 0; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        text-decoration: none;
        color: #0d6626;
        font-family: "Gilroy-Medium";
        font-weight: 400; }
        .breadcrumbs ul li a.current {
          pointer-events: none;
          color: #666; }
      .breadcrumbs ul li:after {
        content: ' ';
        width: 7px;
        height: 7px;
        border-bottom: 1px solid #666;
        border-right: 1px solid #666;
        transform: rotate(-45deg);
        margin: 6px 10px 0px 8px;
        display: inline-block;
        vertical-align: top;
        bottom: 0; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }

/* breadcrumbs */
/* modal */
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }
  .modal .modal__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .modal .modal__block {
    width: 815px;
    background-color: #f9faf9;
    position: absolute;
    padding: 90px 120px;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%); }
    .modal .modal__block .close {
      right: 40px;
      top: 40px; }
    .modal .modal__block h4 {
      font-size: 30px;
      text-align: center;
      padding: 0 10px;
      margin: 0 0 40px;
      font-family: "Gilroy-Bold"; }
    .modal .modal__block p {
      text-align: center;
      font-size: 18px;
      color: #666; }
    .modal .modal__block .policy a {
      background-color: #fff;
      text-decoration: underline;
      font-family: "Gilroy-Regular"; }
    .modal .modal__block .policy {
      padding-left: 19px; }
    .modal .modal__block .flex {
      align-items: center; }
  .modal form {
    display: block;
    margin-top: 30px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      width: 100%;
      min-height: 50px;
      background-color: #fff;
      border: 1px solid #dddddd;
      border-radius: 5px;
      font-family: "Gilroy-Regular";
      font-size: 16px;
      color: #333;
      border: 1px solid #bababa;
      padding: 16px 30px;
      margin-bottom: 20px;
      box-shadow: none;
      line-height: 1.2;
      outline: none; }
      .modal form textarea::placeholder,
      .modal form input[type="text"]::placeholder,
      .modal form input[type="email"]::placeholder,
      .modal form input[type="search"]::placeholder,
      .modal form input[type="password"]::placeholder {
        color: #999; }
    .modal form .g-recaptcha {
      float: right; }
    .modal form .btn {
      margin: 15px auto 0; }
  @media screen and (max-width: 1200px) {
    .modal .modal__block h4 {
      font-size: 24px;
      margin-bottom: 15px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 50px;
      font-size: 16px;
      padding: 14px 15px; } }
  @media screen and (max-width: 1024px) {
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 40px;
      font-size: 14px;
      padding: 10px 15px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    .modal .modal__block h4 {
      font-size: 20px; }
    .modal .modal__block p {
      font-size: 14px; } }
  @media screen and (max-width: 500px) {
    .modal .modal__block {
      width: auto;
      left: 10px;
      right: 10px; } }
  @media screen and (max-width: 420px) {
    .modal form .g-recaptcha {
      transform: scale(0.85);
      transform-origin: 50% 50%; } }

.call--btn {
  position: fixed;
  bottom: 100px;
  right: 40px;
  display: table;
  background-color: #ffcc00;
  font-family: 'FontAwesome';
  border-radius: 100%;
  text-decoration: none !important;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 23px 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  outline: none !important;
  z-index: 1; }
  .call--btn:hover {
    animation-name: phone;
    animation-iteration-count: 1;
    animation-duration: 0.7s; }
  .call--btn i {
    display: block;
    font-size: 35px;
    color: #fff; }
  .call--btn:after, .call--btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: transparent;
    border: 1px solid #ffcc00;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring;
    outline: none; }
  .call--btn:after {
    animation-delay: 0.6s; }
  .call--btn:before {
    animation-delay: 0.8s;
    border: 1px solid #d9af04; }
  @media screen and (max-width: 1440px) {
    .call--btn {
      margin-right: 0;
      right: 35px;
      bottom: 75px; } }
  @media screen and (max-width: 991px) {
    .call--btn {
      width: 50px;
      height: 50px;
      padding-top: 15px;
      right: 15px;
      bottom: 80px; }
      .call--btn i {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .call--btn {
      bottom: 50px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0.1;
    transform: scale(1.7); } }

@keyframes phone {
  20% {
    transform: rotate(-30deg); }
  80% {
    transform: rotate(390deg); }
  100% {
    transform: rotate(360deg); } }

@media (max-width: 840px) {
  .modal .modal__block {
    width: 95%; }
    .modal .modal__block .flex {
      flex-direction: column; }
      .modal .modal__block .flex .policy {
        margin-top: 15px;
        text-align: center;
        padding-left: 0; } }

@media (max-width: 570px) {
  .modal .modal__block {
    padding: 50px 30px; }
  .modal .modal__block p {
    margin-bottom: 0; }
  .policy {
    font-size: 12px; }
  .modal .modal__block .close {
    top: 20px;
    right: 20px; } }

#success-send h4 {
  margin-bottom: 0; }

/* modal */
/* page content */
/* footer */
.footer {
  padding-top: 120px;
  background-color: #f2f2f2; }
  .footer .f-upper .container {
    padding-bottom: 120px; }
  .footer a {
    color: #666666;
    font-size: 16px;
    font-family: "Gilroy-Medium"; }
    .footer a:hover {
      text-decoration: none;
      color: #0d6626; }
    .footer a.active {
      color: #fdc5c5; }
  .footer .f-logo-c {
    padding-bottom: 30px; }
  .footer .col p {
    padding-bottom: 60px; }
  .footer .socials {
    width: 120px;
    display: flex;
    justify-content: space-between; }
    .footer .socials .fa {
      font-size: 18px; }
    .footer .socials a:hover {
      color: #0d6626; }
  .footer .f-list-heading {
    font-size: 20px;
    color: #151515;
    font-weight: bold;
    padding-bottom: 29px; }
  .footer .f-list li {
    padding-bottom: 16px; }
    .footer .f-list li a:hover {
      color: #0d6626; }
    .footer .f-list li a:active {
      color: #fdc5c5; }
  .footer .f-phones a {
    display: block;
    font-size: 20px;
    line-height: 30px;
    color: #151515;
    font-weight: bold; }
  .footer .fa-phone, .footer .fa-envelope {
    top: 5px; }
  .footer .f-cts .fa {
    position: absolute;
    color: #0d6626;
    font-size: 18px;
    left: -22px; }
  .footer .f-cts li {
    position: relative;
    padding-bottom: 30px; }
  .footer .f-mail {
    text-decoration: underline; }
  .footer .copy {
    background-color: #e0e4dc;
    min-height: 60px; }
    .footer .copy .container {
      height: 60px; }
    .footer .copy p, .footer .copy a {
      font-size: 14px;
      margin: 0;
      padding: 0;
      line-height: 1.2;
      font-weight: 400;
      color: #666666; }
    .footer .copy img {
      vertical-align: middle; }
    .footer .copy a:hover {
      color: #0d6626; }

@media (max-width: 930px) {
  .footer .f-upper .container {
    flex-direction: column; }
  .footer .col {
    margin-bottom: 25px; }
  .f-cts .f-list {
    padding-left: 25px; }
  .footer {
    padding-top: 60px; }
  .footer .col p {
    padding-bottom: 15px; }
  .footer .socials {
    padding-bottom: 20px; }
  .footer .f-upper .container {
    padding-bottom: 15px; } }

/* footer */
.pink, .btn-trans {
  color: #151515;
  font-size: 16px;
  border-radius: 6px;
  padding: 17px 40px;
  font-weight: 600;
  transition: 600ms;
  cursor: pointer; }

.pink {
  background-color: #fedede; }
  .pink:hover {
    background: #fdc5c5; }

.btn-trans {
  background: transparent;
  border: 1px solid #959595; }
  .btn-trans:hover {
    background: #DCE4DC; }

.i-slider {
  overflow: hidden;
  position: relative; }
  .i-slider .slider__item {
    position: relative;
    height: calc(100vh - 132px);
    min-height: 400px; }
  .i-slider .i-slider__title {
    font-weight: 700;
    font-size: 60px; }
  .i-slider .btn-trans {
    font-size: 20px;
    margin-top: 50px;
    display: block;
    width: max-content; }
  .i-slider .img-c {
    position: relative;
    width: 100%;
    height: 100%; }
    .i-slider .img-c img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center; }
  .i-slider .slider__item .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%); }
  .i-slider p {
    font-size: 1.125rem;
    line-height: 24px;
    font-weight: 400;
    color: #666666; }

.i-catalog {
  padding-bottom: 120px; }
  .i-catalog .headlining {
    align-items: center;
    margin-bottom: 50px; }
  .i-catalog h2 {
    margin-bottom: 0; }
  .i-catalog .cards {
    flex-wrap: wrap; }
    .i-catalog .cards .plant-card {
      width: 32%;
      box-sizing: border-box;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 50px; }
      .i-catalog .cards .plant-card:hover {
        border-bottom: 1px solid #151515; }
        .i-catalog .cards .plant-card:hover .img-c:after {
          opacity: .4; }
        .i-catalog .cards .plant-card:hover span {
          color: #0d6626; }
      .i-catalog .cards .plant-card span {
        display: block;
        padding: 30px 0;
        margin: 0 auto;
        width: fit-content;
        color: #151515;
        font-size: 20px;
        text-align: center;
        font-weight: bold; }
    .i-catalog .cards .img-c {
      width: 100%;
      position: relative; }
      .i-catalog .cards .img-c:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #0d6525;
        opacity: 0;
        transition: 300ms; }
      .i-catalog .cards .img-c img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        margin: 0;
        vertical-align: bottom; }

.to-smth {
  font-size: 16px;
  color: #151515;
  font-weight: bold;
  border-bottom: 1px solid #e0e4dc;
  height: fit-content;
  padding-bottom: 2px; }

.i-about {
  background-color: #f2f2f2;
  padding: 40px 0 0; }
  .i-about .img-c {
    position: relative;
    bottom: 110px;
    padding-right: 50px; }
  .i-about .i-about-text {
    padding-left: 50px; }
    .i-about .i-about-text p {
      line-height: 24px;
      padding-bottom: 30px; }
    .i-about .i-about-text .to-smth {
      position: relative;
      top: 20px; }

.i-news .news-card {
  width: 48%;
  border-bottom: 1px solid #dddddd;
  transition: 400ms; }
  .i-news .news-card:hover {
    border-bottom: 1px solid #151515; }
    .i-news .news-card:hover .img-c .news-date {
      background-color: #0d6626;
      color: #fff; }
    .i-news .news-card:hover .news-text .news-heading {
      color: #0d6626; }
  .i-news .news-card .img-c {
    position: relative; }
    .i-news .news-card .img-c img {
      width: 100%;
      object-position: center;
      object-fit: cover;
      vertical-align: bottom; }
    .i-news .news-card .img-c .news-date {
      position: absolute;
      background-color: #e0e4dc;
      left: 0;
      bottom: 0;
      color: #666666;
      font-size: 14px;
      padding: 20px 40px;
      display: block;
      transition: 400ms; }
  .i-news .news-card .news-text {
    padding: 40px 0 43px; }
    .i-news .news-card .news-text .news-heading {
      font-size: 20px;
      color: #151515;
      font-weight: bold;
      transition: 400ms;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      margin-bottom: 0;
      overflow: hidden;
      -webkit-line-clamp: 3;
      overflow: hidden; }
    .i-news .news-card .news-text .news-par {
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      padding-top: 15px; }

.news-par {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden; }

.category .to-2 {
  grid-template-columns: 263px 1fr; }

.category .l-col {
  border: 1px solid #dddddd;
  align-self: self-start; }
  .category .l-col a {
    font-size: 20px;
    font-weight: 700;
    font-family: "Gilroy-Medium";
    letter-spacing: 0.2px;
    display: block;
    width: 100%;
    height: 100%;
    padding: 28px 20px 28px 30px;
    border-bottom: 1px solid #ddd; }
  .category .l-col li:last-child a {
    border-bottom: 0; }
  .category .l-col a.active {
    color: #0d6626;
    border-bottom: 1px solid #151515; }

.category .card {
  transition: 400ms; }
  .category .card .img-c {
    position: relative; }
    .category .card .img-c::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #0d6525;
      opacity: 0;
      -webkit-transition: 300ms;
      transition: 300ms; }
  .category .card:hover {
    border-bottom: 1px solid #151515; }
    .category .card:hover .img-c::after {
      opacity: .4; }
  .category .card img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.new-mark {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #0d6626;
  color: #fff;
  font-family: "Gilroy - Semi Bold";
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  right: 15px;
  z-index: 1; }

.card {
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd; }
  .card p {
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.16px;
    font-family: "Gilroy-Medium"; }
    .card p b {
      color: #151515;
      font-weight: 700;
      font-size: 18px;
      font-family: "Gilroy-Medium";
      padding-bottom: 19px; }

p.price {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  font-family: "Gilroy-Bold" !important;
  letter-spacing: 0.2px;
  color: #151515 !important; }

.card-title {
  padding-top: 30px; }

.card-page .to-2 {
  grid-template-columns: 555px 1fr; }

.card-page .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.card-page .cat--unit__for {
  margin-bottom: 30px; }

.card-page .cat--unit__nav {
  width: 93%;
  margin: 0 auto 30px; }
  .card-page .cat--unit__nav .img-c {
    width: 115px;
    height: 115px;
    border: 1px solid #ddd;
    margin: 0 7.5px; }
    .card-page .cat--unit__nav .img-c img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-position: center; }
  .card-page .cat--unit__nav .slick-arrow {
    font-size: 0; }
  .card-page .cat--unit__nav .slick-prev::before, .card-page .cat--unit__nav .slick-next::before {
    width: 9px;
    height: 9px;
    opacity: 1;
    border-bottom: 2px solid #333;
    border-right: 2px solid #333;
    position: relative; }
  .card-page .cat--unit__nav .slick-prev::before {
    transform: rotate(135deg);
    left: 10px; }
  .card-page .cat--unit__nav .slick-next::before {
    transform: rotate(-45deg); }

.card-page .r-col p {
  color: #666666;
  font-family: "Gilroy-Medium"; }

.card-page .price {
  margin-bottom: 30px; }

.card-page .upper {
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px; }

.card-page ol {
  list-style-position: inside;
  padding-left: 0;
  color: #666666;
  font-family: "Gilroy-Regular";
  line-height: 24px;
  margin-bottom: 30px; }

.card-page .bottom p {
  color: #666666;
  font-family: "Gilroy-Regular"; }

.card-page .slick-slide {
  margin: 0 15px; }

.descr-title {
  font-family: "Gilroy-Bold"; }

.slick-slide {
  outline: none; }

.separate {
  width: 100%;
  height: 1px;
  background-color: #dddddd; }

.else-slider .slick-dots {
  bottom: -70px; }

.else-slider .slick-dots li {
  border: 2px solid #d1d1d1;
  height: 12px;
  width: 12px;
  margin: 0 7.5px;
  border-radius: 50%;
  overflow: hidden;
  transition: 400ms; }

.else-slider .slick-dots li.slick-active button:before {
  display: none; }

.else-slider li.slick-active {
  border: 2px solid #0d6626;
  background-color: #0d6626; }

.else-slider a .img-c {
  overflow: hidden; }

.else-slider a img {
  transition: 400ms; }

.else-slider a:hover {
  border-bottom: 1px solid #151515; }
  .else-slider a:hover img {
    transform: scale(1.05); }

.else .container {
  padding-bottom: 110px; }

.cts-page div, .cts-page a, .cts-page .fa {
  line-height: 1.2;
  font-weight: 400;
  color: #666666; }

.cts-page .l-col > div {
  padding: 30px 24px;
  border-bottom: 1px solid #ddd; }
  .cts-page .l-col > div:first-child {
    border-top: 1px solid #ddd; }

.cts-page .col--location:after, .cts-page .col--email:after, .cts-page .col--time:after,
.cts-page .col--email:after {
  top: 30px; }

.cts-page .col--phone a {
  display: block;
  font-family: "Gilroy - Semi Bold";
  color: #151515;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600; }

.cts-page .col--phone:after {
  top: 34px; }

.cts-page .col--email a {
  text-decoration: underline; }

.cts-page .l-col .socials {
  padding-left: 0; }
  .cts-page .l-col .socials .fa {
    font-size: 17px;
    margin-right: 30px;
    transition: 400ms; }
    .cts-page .l-col .socials .fa:hover {
      color: #006633; }

.g-bg {
  background-color: #e0e4dc; }
  .g-bg .container {
    padding: 120px 15px 120px; }

.contactus .grid {
  grid-template-columns: 52% 48%; }

.contactus p {
  font-size: 18px;
  padding-bottom: 30px; }

.contactus textarea {
  height: 120px; }

.contactus .policy {
  font-size: 14px;
  line-height: 19px;
  margin-top: 19px; }
  .contactus .policy a {
    font-family: "Gilroy - Semi Bold";
    font-weight: 500;
    text-decoration: underline;
    color: #0d6626; }

.catalog-page .container {
  padding-bottom: 0; }

.about-page .img-c {
  overflow: hidden; }

.about-page .img-c, .about-page img {
  width: 100%;
  height: 100%; }

.about-page img {
  object-fit: cover;
  object-position: center;
  display: block; }

.about-page p {
  line-height: 24px;
  colro: #666; }

.about-page .green {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  color: #0d6626; }

.about-page .descr-title {
  margin-bottom: 10px; }

.about-page .check--list {
  padding-top: 49px; }
  .about-page .check--list li:before {
    top: .45em; }
  .about-page .check--list li {
    padding-bottom: 15px; }

.m {
  display: none; }

@media (max-width: 1170px) {
  .wrapper .container, .container {
    width: 100%; }
  .to-3 {
    grid-template-columns: repeat(auto-fit, minmax(240px, 265px));
    grid-auto-flow: dense; }
  .pagination {
    grid-column-start: 1;
    grid-column-end: 3; } }

@media (max-width: 1141px) {
  .i-slider .i-slider__title {
    font-size: 44px; }
  .logo-link {
    width: 180px; }
    .logo-link img {
      width: 100%;
      height: 100%;
      -object-fit: contain; } }

@media (max-width: 1090px) {
  .cts-page .to-2 {
    grid-template-columns: 100%; } }

@media (max-width: 1020px) {
  .down-nv-list {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    text-align: center; }
    .down-nv-list a {
      padding: .7em 1.1em;
      display: block; }
  .logo-link {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%); }
  .m, .m button.pink {
    display: block; }
  .m button.pink {
    margin: 10px auto 0px; }
  .m-inline {
    display: inline-block; }
  .h-langs.m-inline {
    margin-left: auto;
    margin-right: 10px;
    position: relative; }
    .h-langs.m-inline .l-select {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      background: #fff;
      box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1); }
      .h-langs.m-inline .l-select a {
        display: block;
        width: 100%;
        height: 100%;
        padding: .5em .9em; }
  .header .dw-nv .drop-phones {
    background: #fff;
    box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1); }
  .header .dw-nv .drop-phones a {
    display: block;
    width: 100%;
    height: 100%;
    padding: .5em .9em; }
  .m .fa-phone {
    color: #0d6626; }
  .m .fa-chevron-down {
    position: relative;
    bottom: 2px; }
  .card-page .to-2 {
    grid-template-columns: 100%; }
  .category .to-2 {
    grid-template-columns: 1fr; }
  .to-3 {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); }
  .cts-page iframe {
    width: 100%; }
  body {
    padding-top: 50px; }
  .i-slider .i-slider__title {
    font-size: 35px; }
  .i-slider p {
    font-size: 16px; }
  .i-slider .btn-trans {
    font-size: 16px; }
  .i-catalog h2, .index h2 {
    font-size: 28px; }
  .i-about .img-c {
    padding-right: 0;
    width: 400px; }
    .i-about .img-c img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .i-about .i-about-text {
    padding-left: 15px; }
  .i-about {
    padding-bottom: 50px; }
  .i-news .news-card .news-text .news-heading {
    font-size: 18px; }
  .i-news .news-card .img-c .news-date {
    padding: 10px 20px; }
  .i-catalog .cards .plant-card span {
    font-size: 16px; }
  .i-catalog {
    padding-bottom: 30px; }
  .i-about .i-about-text p {
    padding-bottom: 10px; } }

@media (max-width: 780px) {
  .i-catalog .headlining {
    margin-bottom: 25px; }
  .category .l-col a {
    padding: 15px 20px 14px 20px;
    font-size: 16px; }
  .new-mark {
    width: 36px;
    height: 36px; }
  .to-2 {
    grid-template-columns: 1fr; }
  .about-page .l-col {
    grid-row: 2/3; }
  .about-page .check--list li {
    padding-bottom: 0; }
  .about-page .check--list {
    padding-top: 30px; }
  .cts-page .col--phone a {
    font-size: 18px; }
  .cts-page .l-col > div {
    padding: 20px 24px; }
  .cts-page .col--location:after, .cts-page .col--email:after, .cts-page .col--time:after, .cts-page .col--email:after {
    top: 21px; }
  .cts-page .col--phone:after {
    top: 23px; }
  .g-bg .container {
    padding: 40px 15px 40px; }
  .contactus p {
    font-size: 14px;
    padding-bottom: 5px; }
  .wrapper textarea, .wrapper input[type="text"], .wrapper input[type="email"], .wrapper input[type="search"], .wrapper input[type="password"], .page textarea, .page input[type="text"], .page input[type="email"], .page input[type="search"], .page input[type="password"] {
    padding: 15px 20px; }
  .cts-page .r-co .img-c img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .cts-page .r-co .img-c {
    position: absolute;
    bottom: 0;
    opacity: .3;
    z-index: 1; }
  .cts-page form {
    position: relative;
    z-index: 2; } }

@media (max-width: 480px) {
  .to-smth {
    font-size: 14px; }
  .i-catalog .cards .plant-card {
    width: 48%; }
  .i-catalog .cards .plant-card span {
    padding: 15px 0; }
  .i-about {
    position: relative; }
  .i-about .img-c {
    width: calc(100% - 30px);
    position: absolute;
    padding: 0;
    opacity: .40; }
  .i-news .container {
    padding: 40px 15px 50px; }
  .i-news .flex {
    flex-direction: column; }
  .i-news .news-card {
    width: 100%;
    margin-bottom: 30px; }
  .i-news .news-card .news-text {
    padding: 20px 0 20px; }
  .card-title {
    padding-top: 20px; }
  .card p {
    font-size: 14px; }
  p.price, .card p b {
    font-size: 18px; }
  .cts-page iframe {
    height: 270px; }
  .footer .copy .flex {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column; }
    .footer .copy .flex p {
      padding-bottom: 5px; } }

@media (max-width: 400px) {
  .logo-link {
    width: 135px; }
  .footer .f-cts li {
    padding-bottom: 15px; } }
