@import "header";
@import "main";
@import "page";
@import "footer";


.pink, .btn-trans {
  color: #151515;
  font-size: 16px;
  border-radius: 6px;
  padding: 17px 40px;
  font-weight: 600;
  transition: 600ms;
  cursor: pointer;
}
.pink {
  background-color: #fedede;
  &:hover {background: #fdc5c5;}
}
.btn-trans {
  background: transparent;
  border: 1px solid #959595;
  &:hover {background: #DCE4DC;}
}

.i-slider {
  overflow: hidden;
  .slider__item {position: relative; 
    height: calc(100vh - 132px); min-height: 400px;}
  position: relative;
  .i-slider__title{font-weight: 700; font-size: 60px;}
  .btn-trans {
      font-size: 20px;
      margin-top: 50px;
      display: block;
      width: max-content;
  }
  .img-c {
      position: relative;
      width: 100%;
      height: 100%;
      img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: center;
      }
  }
  .slider__item .container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
  }
  p {
      font-size: 1.125rem;
      line-height: 24px;
      font-weight: 400;
      color: #666666;
  }

}

.i-catalog {
  padding-bottom: 120px;
  .headlining {align-items: center; margin-bottom: 50px;}
  h2 {margin-bottom: 0;}
  .cards {
      flex-wrap: wrap;
      .plant-card {
          width: 32%;
          box-sizing: border-box;
          border-bottom: 1px solid #dddddd;
          margin-bottom: 50px;
          &:hover {
              border-bottom: 1px solid #151515;
              .img-c:after {
                  opacity: .4;
              }
              span {color: #0d6626;}
          }
          span {
              display: block;
              padding: 30px 0;
              margin: 0 auto;
              width: fit-content;
              color: #151515;
              font-size: 20px;
              text-align: center;
              font-weight: bold;
          }
      }
      .img-c {
          width: 100%;
          position: relative;
          &:after {
              position: absolute;
              content: '';
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #0d6525;
              opacity: 0;
              transition: 300ms;
          }
          img {
              width: 100%;
              object-fit: cover;
              object-position: center;
              margin: 0;
              vertical-align: bottom;
          }
      }
  }
}
.to-smth {
  font-size: 16px;
  color: #151515;
  font-weight: bold;
  border-bottom: 1px solid #e0e4dc;
  height: fit-content;
  padding-bottom: 2px;
}

.i-about {
  background-color: #f2f2f2;
  padding: 40px 0 0;
  .img-c {
      position: relative;
      bottom: 110px;
      padding-right: 50px;
  }
  .i-about-text {
      padding-left: 50px;
      p {
          line-height: 24px;
          padding-bottom: 30px;
      }
      .to-smth {
          position: relative;
          top: 20px;
      }
  }
}

.i-news {
  .news-card {
      width: 48%;
      border-bottom: 1px solid #dddddd;
      transition: 400ms;
      &:hover {
          border-bottom: 1px solid #151515;
          .img-c .news-date {background-color: #0d6626; color: #fff;}
          .news-text .news-heading {
            color: #0d6626;
          }

      }
      .img-c {
          position: relative;
          img {
              width: 100%;
              object-position: center;
              object-fit: cover;
              vertical-align: bottom;
          }
          .news-date {
              position: absolute;
              background-color: #e0e4dc;
              left: 0;
              bottom: 0;
              color: $g6;
              font-size: 14px;
              padding: 20px 40px;
              display: block;
              transition: 400ms;
          }
      }
      .news-text {
          padding: 40px 0 43px;
          .news-heading {
              font-size: 20px;
              color: #151515;
              font-weight: bold;
              transition: 400ms;
              @include cut-text();
              -webkit-line-clamp: 3;
              overflow: hidden;
          }
          .news-par {
              font-size: 16px;
              line-height: 24px;
              color: $g6;
              padding-top: 15px;
          }
      }
  }
}

.news-par {@include cut-text}

.category {
  .to-2 {
    grid-template-columns: 263px 1fr;
  }
  .l-col {
    border: 1px solid #dddddd;
    align-self: self-start;
    a {
      font-size: 20px;
      font-weight: 700;
      font-family: $medium;
      letter-spacing: 0.2px;
      display: block; width: 100%; height: 100%;
      padding: 28px 20px 28px 30px;
      border-bottom: 1px solid #ddd;
      
    }
    li:last-child a {border-bottom: 0;}
    a.active {color: #0d6626; border-bottom: 1px solid #151515;}
  }
}

.category .card {
  transition: 400ms;
  .img-c {position: relative;
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #0d6525;
      opacity: 0;
      -webkit-transition: 300ms;
      transition: 300ms;
    } 
  }
  &:hover {
    .img-c::after {opacity: .4}
    border-bottom: 1px solid #151515;
  }
  img {display: block; width: 100%; height: 100%; object-fit: cover;}
}

.new-mark {
  position: absolute;
  width: 40px; height: 40px;
  background-color: #0d6626; color: #fff;
  font-family: "Gilroy - Semi Bold";text-transform: uppercase;
  font-size: 12px; border-radius: 50%; overflow: hidden;
  display: flex; align-items: center; justify-content: center;
  top: -15px; right: 15px; z-index: 1;
}

.card {
  padding-bottom: 15px; border-bottom: 1px solid #ddd;
  p {
    color: #666666;font-size: 16px;font-weight: 400;
    letter-spacing: 0.16px;font-family: $medium; 

    b {
      color: #151515;
      font-weight: 700;font-size: 18px; font-family: $medium; padding-bottom: 19px;
    }
  }
}

p.price {
  position: relative;
  font-size: 20px; 
  font-weight: 700;
  font-family: $bold !important;
  letter-spacing: 0.2px;
  color: #151515 !important;
}

.card-title {padding-top: 30px;}

.card-page {
  .to-2 {grid-template-columns: 555px 1fr;}
  .slick-slide img {width: 100%; height: 100%; object-fit: cover; object-position: center;}
  .cat--unit__for {margin-bottom: 30px;}
  .cat--unit__nav {
    width: 93%; margin: 0 auto 30px;
    .img-c {
      width: 115px; height: 115px; border: 1px solid #ddd;
      margin: 0 7.5px;
      img {width: 100%; height: 100%; -o-object-fit: cover;
      object-position: center;}
    }
    .slick-arrow {font-size: 0;}
    .slick-prev::before, .slick-next::before {
      width: 9px; height: 9px;
      opacity: 1;
      border-bottom: 2px solid #333;
      border-right: 2px solid #333;
      position: relative;
    }
    .slick-prev::before {transform: rotate(135deg); left:10px;}
    .slick-next::before {transform: rotate(-45deg);}
  }
  .r-col p {
    color: #666666; font-family: $medium;  }
  .price {margin-bottom: 30px;}
  .upper {padding-bottom: 30px; border-bottom: 1px solid #ddd; margin-bottom: 30px;}
  ol {
    list-style-position: inside; padding-left: 0;
    color: #666666;
    font-family: $regular;
    line-height: 24px; margin-bottom: 30px;
  }
  .bottom p {color: #666666; font-family: $regular; }
  .slick-slide {margin: 0 15px;;}
}

.descr-title {font-family: $bold;}

.slick-slide {outline: none}
.separate {
  width: 100%; height: 1px;background-color: #dddddd;
}

.else-slider {
  .slick-dots {bottom: -70px}
  .slick-dots li {
    border: 2px solid #d1d1d1;
    height: 12px;
    width: 12px;
    margin: 0 7.5px;
    border-radius: 50%; overflow: hidden;
    transition: 400ms;
  }
  .slick-dots li.slick-active button:before {display: none;}
  li.slick-active {
    border: 2px solid #0d6626;
    background-color: #0d6626;
  }
  a {
    .img-c {overflow: hidden;}
    img {transition: 400ms;}
    &:hover {
      border-bottom: 1px solid #151515;
      img {transform: scale(1.05);}
    }
  }
}

.else .container {
  padding-bottom: 110px;
}

.cts-page {
  div, a, .fa {
    line-height: 1.2;
    font-weight: 400;
    color: #666666;
  }
  .l-col>div {
    padding: 30px 24px; 
    border-bottom: 1px solid #ddd;
    &:first-child {border-top: 1px solid #ddd;}
  }
  .col--location:after, .col--email:after, .col--time:after,
  .col--email:after {top: 30px}
  .col--phone a {
    display: block; font-family: "Gilroy - Semi Bold";
    color: #151515;
    font-size: 20px;
    line-height: 1.4; font-weight: 600;
  }
  .col--phone:after {top: 34px}
  .col--email a {
    text-decoration: underline;
  }
  .l-col .socials { padding-left: 0;
    .fa {
      font-size: 17px; margin-right: 30px; transition: 400ms;
      &:hover {color: #006633;}
    }
  }
}

.g-bg {
  background-color: #e0e4dc;
  .container {padding: 120px 15px 120px;}
}

.contactus {
  .grid {grid-template-columns: 52% 48%;}
    p { font-size: 18px; padding-bottom: 30px;}
    textarea {height: 120px;}
    .policy {
      font-size: 14px;line-height: 19px;
      margin-top: 19px;
      a {
        font-family: $semi;
        font-weight: 500;
        text-decoration: underline;
        color: #0d6626;
      }
    }
}


.catalog-page .container {padding-bottom: 0;}


.about-page {
  .img-c {overflow: hidden;}
  .img-c, img {
    width: 100%; height: 100%;
  }
  img {object-fit: cover; object-position: center; display: block;}
  p {line-height: 24px; colro: #666}
  .green {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    color: #0d6626;
  }
  .descr-title {margin-bottom: 10px;}
  .check--list {
    padding-top: 49px;
    li:before {top: .45em;}
    li {padding-bottom: 15px;}
  }
}

.m {display: none;}




@media(max-width: 1170px) {
  .wrapper .container, .container {width: 100%;}
  .to-3 {grid-template-columns: repeat( auto-fit, minmax(240px, 265px));
  grid-auto-flow: dense;}
  .pagination {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media(max-width: 1141px) {
  .i-slider .i-slider__title {font-size: 44px;}
  .logo-link {width: 180px;
  img {width: 100%; height: 100%; -object-fit: contain;;}}
}

@media(max-width: 1090px) {
  .cts-page .to-2 {
    grid-template-columns: 100%;
  }
}

@media(max-width: 1020px) {
  .down-nv-list {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    text-align: center;
    a {
      padding: .7em 1.1em; display: block;
    }
  }
  .logo-link {
    position: absolute; left: 50%; top: 55%; transform: translate(-50%, -50%);
  }
  .m, .m button.pink {display: block;}
  .m button.pink {margin: 10px auto 0px;}
  .m-inline {display: inline-block;}
  .h-langs.m-inline {
    margin-left: auto; margin-right: 10px; position: relative;
    .l-select {
      display: none;
      position: absolute; top: 100%; right: 0;
      background: #fff;
      box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
      a {
        display: block; width: 100%; height: 100%; padding: .5em .9em;
      }
    }
  }
  .header .dw-nv .drop-phones {
    background: #fff;
    box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
  }
  .header .dw-nv .drop-phones a {display: block;width: 100%; height: 100%; padding: .5em .9em;}
  .m .fa-phone {color: #0d6626}
  .m .fa-chevron-down {position: relative; bottom: 2px;}
  .card-page .to-2 {grid-template-columns: 100%;}
  .category .to-2 {grid-template-columns: 1fr;}
  .to-3 { grid-template-columns: repeat( auto-fit, minmax(140px, 1fr)); }
  .cts-page iframe {width: 100%}


  body {padding-top: 50px;}
  .i-slider .i-slider__title {font-size: 35px;}
  .i-slider p {font-size: 16px;}
  .i-slider .btn-trans {font-size: 16px;}
  .i-catalog h2, .index h2 {font-size: 28px;}
  .i-about .img-c { padding-right: 0;
    width: 400px; 
    img {width: 100%; height: 100%; object-fit: contain;}
  }
  .i-about .i-about-text {padding-left: 15px;}
  .i-about {padding-bottom: 50px;}
  .i-news .news-card .news-text .news-heading {font-size: 18px;}
  .i-news .news-card .img-c .news-date {padding: 10px 20px;}
  .i-catalog .cards .plant-card span {font-size: 16px;}
  .i-catalog {padding-bottom: 30px;}
  .i-about .i-about-text p {padding-bottom: 10px;}
}

@media(max-width: 780px) {
  .i-catalog .headlining {margin-bottom: 25px;}
  .category .l-col a {
    padding: 15px 20px 14px 20px;
    font-size: 16px;
  }
  .new-mark {width: 36px; height: 36px;}
  .to-2 {grid-template-columns: 1fr;}
  .about-page .l-col {grid-row: 2/3;}
  .about-page .check--list li {padding-bottom: 0;}
  .about-page .check--list {padding-top: 30px;}
  .cts-page .col--phone a {font-size: 18px;}
  .cts-page .l-col > div {padding: 20px 24px;}
  .cts-page .col--location:after, .cts-page .col--email:after, .cts-page .col--time:after, .cts-page .col--email:after {top: 21px}
  .cts-page .col--phone:after {top: 23px}
  .g-bg .container {padding: 40px 15px 40px;}
  .contactus p {font-size: 14px; padding-bottom: 5px;}
  .wrapper textarea, .wrapper input[type="text"], .wrapper input[type="email"], .wrapper input[type="search"], .wrapper input[type="password"], .page textarea, .page input[type="text"], .page input[type="email"], .page input[type="search"], .page input[type="password"] {
    padding: 15px 20px;
  }
  .cts-page .r-co .img-c img {width: 100%; height: 100%; object-fit: cover;}
  .cts-page .r-co .img-c {
    position: absolute;
    bottom: 0;
    opacity: .3;
    z-index: 1;
  }
  .cts-page form {
    position: relative; z-index: 2;
  }
}

@media(max-width: 480px) {
  .to-smth {font-size: 14px;}
  .i-catalog .cards .plant-card {width: 48%}
  .i-catalog .cards .plant-card span {padding: 15px 0}
  .i-about {position: relative;}
  .i-about .img-c {width: calc(100% - 30px); position: absolute;padding: 0; opacity: .40}
  .i-news .container {padding: 40px 15px 50px}
  .i-news .flex {
    flex-direction: column;
  }
  .i-news .news-card {width: 100%; margin-bottom: 30px;}
  .i-news .news-card .news-text {padding: 20px 0 20px}
  .card-title {padding-top: 20px;}
  .card p {font-size: 14px;}
  p.price, .card p b {font-size: 18px;}
  .cts-page iframe {height: 270px}
  .footer .copy .flex {align-items: flex-start; 
    justify-content: center; 
    flex-direction: column;
    p {padding-bottom: 5px;}
  }
}

@media(max-width: 400px) {
  .logo-link {width: 135px}
  .footer .f-cts li {padding-bottom: 15px;}
} 